/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .footer {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    color: white;
    padding: 2rem 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    padding: 0 24px;
  }
  
  .footer-logo a {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 1rem;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-social {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .footer-social a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: #ffdd57;
  }
  
  .footer-bottom {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  @media (min-width: 768px) {
    .footer-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .footer-links {
      margin-bottom: 0;
    }
  
    .footer-social {
      margin-bottom: 0;
    }
  }
  