/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  /* About page container */
  .about-page {
    padding: 4rem 2rem;
    background-color: #f4f4f9;
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-page h2 {
    text-align: center;
    font-size: 2.75rem;
    color: #333;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
  }
  
  .about-page h2::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: #4a90e2;
    margin: 1rem auto 0;
    border-radius: 2px;
  }
  
  .about-page p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    text-align: center;
  }
  
  .about-page ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .about-page ul li {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 0.75rem;
    line-height: 1.6;
  }
  
  .services-link {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: #4a90e2;
    padding: 1rem 2rem;
    margin: 2rem auto;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    max-width: 200px;
  }
  
  .services-link:hover {
    background-color: #357abd;
    transform: translateY(-2px);
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .about-page {
      padding: 2rem 1rem;
    }
  
    .about-page h2 {
      font-size: 2.25rem;
    }
  
    .about-page p {
      font-size: 1rem;
    }
  
    .about-page ul {
      margin-left: 1rem;
    }
  
    .services-link {
      font-size: 1rem;
      padding: 0.75rem 1.5rem;
    }
  }
  