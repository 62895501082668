.home-page {
  padding: 2rem;
}

.hero-section {
  text-align: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  padding: 4rem 2rem;
  margin-bottom: 2rem;
}

.services-overview {
  text-align: center;
}

.service-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.service-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 300px;
  height: max-content;
  margin: 20px;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  font-size: 1.5em;
  margin: 20px 0;
  color: #333;
}

.service-card img {
  transition: transform 0.3s ease-in-out;
}

.service-card:hover img {
  transform: scale(1.1);
}

.service-card p {
  color: #666;
  padding: 0 20px;
  margin: 20px 0;
}

.service-card button {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-bottom: 20px;
}

.service-card button:hover {
  background: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .hero-section {
    padding: 2rem 1rem;
    margin-bottom: 1rem;
  }

  .service-card {
    flex: 1 1 calc(100% - 2rem);
    max-width: calc(100% - 2rem);
  }

  .service-cards {
    flex-direction: column;
    align-items: center;
  }
}
