/* Backdrop for modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Box */
.modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

/* Heading */
.modal h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

/* Phone Input Container */
.phone-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

/* Country Code Dropdown */
.country-code-dropdown {
  height: 40px;
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  font-size: 14px;
  cursor: pointer;
}

/* Phone Number Input */
.phone-input-container input {
  flex: 1;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Modal Buttons */
.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.save-button,
.close-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}


.save-button {
  padding: 8px 16px;
  background-color: #667eea;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button {
  padding: 8px 16px;
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #5a67d8;
}


/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
