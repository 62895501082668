/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

/* Services page container */
.services-page {
  padding: 4rem 2rem;
  background-color: #f4f4f9;
  min-height: 100vh;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}


.category-tag {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);

  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.category-tag:hover,
.category-tag.active {
     background: #e2d1f4 100%;
     color:black

}
.category-tag.clear-tag {
  background: lightgrey;
  color: black;
}


.services-page h2 {
  text-align: center;
  font-size: 2.75rem;
  color: #333;
  margin-bottom: 3rem;
  font-weight: 700;
  position: relative;
}

.services-page h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #4a90e2;
  margin: 1rem auto 0;
  border-radius: 2px;
}

/* Services list grid */
.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  align-items: stretch;
}

.filter-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* .location-button{
  padding: 10px 20px;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

.location-button {
  padding: 8px 16px;
  background-color: #667eea;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-button {
  padding: 8px 16px;
  /* background-color: #f4f4f4; */
  background: lightgrey;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

/* .location-button:hover,
.clear-button:hover {
  background-color: white;
} */

.no-location {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}



/* Individual service item */
.service-item {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  margin: 1rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.service-item img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.service-item:hover img {
  transform: scale(1.05);
}

.service-item h3 {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: 600;
}

.service-item p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

/* Buttons Container */
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Call Button */
.call-button {
  background-color: #28a745;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.9rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
}

.call-button a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.call-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

/* WhatsApp Button */
.whatsapp-button {
  background-color: #25d366;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.9rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
}

.whatsapp-button a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.whatsapp-button:hover {
  background-color: #1ebc5b;
  transform: translateY(-2px);
}

.filter-location-buttons{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

/* Link to service details */
.service-item a.view-details {
  color: #ffffff;
  background-color: #4a90e2;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.service-item a.view-details:hover {
  background-color: #357abd;
  transform: translateY(-2px);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .services-page {
    padding: 2rem 1rem;
  }

  .services-list {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .service-item {
    margin: 0.5rem 0;
  }
}

/* Filter Section */
.filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.filter-section input,
.filter-section select {
  width: 200px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-section input:focus,
.filter-section select:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.2);
}

/* Responsive Filter Section */
@media (max-width: 768px) {
  .filter-section {
    flex-direction: column;
    gap: 1rem;
  }

  .filter-section input,
  .filter-section select {
    width: 100%;
  }
}
