/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  /* Contact page container */
  .contact-page {
    padding: 4rem 2rem;
    background-color: #f4f4f9;
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-page h2 {
    text-align: center;
    font-size: 2.75rem;
    color: #333;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
  }
  
  .contact-page h2::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: #4a90e2;
    margin: 1rem auto 0;
    border-radius: 2px;
  }
  
  .contact-page p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
    line-height: 1.6;
    text-align: center;
  }
  
  /* Contact info container */
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .contact-icon {
    font-size: 2rem;
    color: #4a90e2;
    margin-right: 1rem;
  }
  
  .contact-item a {
    font-size: 1.2rem;
    color: #4a90e2;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .contact-item a:hover {
    color: #357abd;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .contact-page {
      padding: 2rem 1rem;
    }
  
    .contact-page h2 {
      font-size: 2.25rem;
    }
  
    .contact-page p {
      font-size: 1rem;
    }
  
    .contact-item a {
      font-size: 1rem;
    }
  }
  